.alertModalCustom {
  background: #034f89;
  color: #fff;
  position: relative;
}

.alertModalFooterCustom {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ffffff;
}

// .alertModalCustom::before {
//   content: "X";
//   position: absolute;
//   width: 38px;
//   height: 38px;
//   background: #ffffff;
//   color: #034f89;
//   top: -20px;
//   right: -20px;
//   z-index: 9999999;
//   border-radius: 50%;
//   font-size: 16px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: bold;
//   border: 1px solid #034f89;
// }
