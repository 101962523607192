@import "../../assets/sass/variable.scss";
.anchor-text {
	font-weight: bold;
	font-style: italic;	
	color: $blue-color;
	text-decoration: none;
	padding-bottom: 1px;
	border-bottom: 1px solid transparent;
	transition: all 0.5s;
	&:hover {
		text-decoration: none;
		border-color: $blue-color;
	}
}
.back-anchor {
	position: absolute;
	left: 0;
	color: $yellow-color;
	margin: 30px;
}