@import "../../assets/sass/variable.scss";
.wrap-lang {
	display: inline-block;
	float: right;
	ul {
		text-align: center;
		padding: 0;
	    margin: 0 !important;
	    list-style-type: none;
	    li {
	    	padding: 0;
		    margin: 0;
		    list-style-type: none;
		    display: inline-block;
		    vertical-align: middle;
		    a {
		    	padding: 0 7px;
		    	font-size: 16px;
			    color: $blue-color;
			    text-decoration: none;
			    display: block;
			    line-height: 1;
				transition: all 0.3s;
				vertical-align: middle;
			    &:hover {
			    	color: $white-color;
			    }
		    }
		    &.active {
		    	a {
			    	color: $white-color;
		    	}
		    }
		    &:first-child {
		    	border-right: 1px solid $blue-color;
		    }
	    }
	}
}