@import "../../assets/sass/variable.scss";
.btn {
	width: auto;
	min-width: 100px;
	border-radius: 7px;
	color: #fff;
	transition: all 0.3s;
	border: 2px solid;
	font-weight: bold;
	// margin: 10px 0 20px;
	margin: 10px 15px;
	&:hover {
		background-color: $white-color;
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
	}
	&:disabled {
		color: $white-color;
	}
	&.btn-auto {
		width: auto;
		margin: 5px;
		padding: 2px 7px;
	}
	&.reverse-blue-btn {
		&:hover {
			background-color: $blue-color;
			text-decoration: none;
		}
	}
}
.yellow-btn {
	background-color: $yellow-color;
	border-color: $yellow-color;
	svg {
		margin-right: 5px;
	}
	&:hover, &:focus {
		color: $yellow-color;
	}
}
.blue-btn {
	background-color: $blue-color;
	border-color: $blue-color;
	svg {
		margin-left: 5px;
	}
	&:hover {
		color: $blue-color;
	}
}
.reverse-blue-btn {
	background-color: $white-color;
	border-color: $blue-color;
	color: $blue-color;
	svg {
		margin-left: 5px;
	}
	&:hover {
		color: $white-color;
	}
}
.redirect-btn {
	width: auto;
	height: auto;
	padding: 10px 20px;
}