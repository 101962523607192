@import "../assets/sass/variable.scss";
.summary-content {
	padding: 15px;
	.title {
		text-transform: uppercase;
		color: $yellow-color;
		font-weight: bold;
		font-size: 24px;
		margin: 20px 0;
		margin-bottom: 5px;
	}
	.section {
		text-align: left;
		font-size: 14px;
		h1 {
			text-transform: uppercase;
		}
		.section-title {
			color: $blue-color;
		}
		.table-summary {
			tr {
				td {
					vertical-align: middle;
					&:first-child {
						text-transform: capitalize;
					}
				}
			}
		}
	}
}
// MOBILE
@media (max-width: 991px) {
	.summary-content {
		padding: 0;
	}
}