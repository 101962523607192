@import "../assets/sass/variable.scss";
.fade-enter.wrap-form {
  opacity: 0;
}
.fade-enter-active.wrap-form {
  opacity: 1;
}
.fade-exit.wrap-form {
  opacity: 1;
}
.fade-exit-active.wrap-form {
  opacity: 0;
}
.fade-enter-active.wrap-form,
.fade-exit-active.wrap-form {
  transition: opacity 100ms;
}

.registration-status {
	.heading {
		text-transform: uppercase;
	    color: $blue-color;
	    font-weight: bold;
	    font-size: 24px;
	    margin: 25px 0;
	}
	.wrap-identity {
		display: flex;
		flex-direction: row;
		margin: 0 25px;
		padding-bottom: 10px;
		border-bottom: 1px solid #e4e4e4;
		.column {
			flex: calc(100%/3);
			text-align: left;
			label {
				display: block;
				font-size: 16px;
				color: #c4c4c4;
				margin: 0;
				text-transform: uppercase;
			}
			span {
				display: block;
				color: $black-color;
			}
		}
	}

	.wrap-summary {
		padding: 30px 175px;
		text-align: left;
		.step {
			margin-bottom: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid #e4e4e4;
			.title {
				font-weight: bold;
				font-size: 22px;
				color: #2e2e2e;
				.icon {
					margin: 0 7px;
					border: 1px solid $black-color;
					color: $black-color;
					border-radius: 50%;
					padding: 3px;
				}
			}
			&.done {
				.title {
					color: $green-color;
					.icon {
						border: 1px solid $green-color;
						color: $green-color;
					}
				}
			}
			.link-download {
				margin-left: 45px;
				border-left: 2px solid #c4c4c4;
				padding-left: 15px;
				color: #2e2e2e;
				font-weight: bold;
				.icon {
					font-size: 12px;
					margin-right: 5px;
				}
				a,.not-yet,.not-available, span {
					font-size: 12px;
				}
				a {
					color: #2e2e2e;
				}
				.not-available {
					color: #a40000;
				}
				.form-app-doclink, .term-service-doclink {
					.icon {
						color: $blue-color;
					}
				}
				.sre,.sid {
					.icon {
						border: 1px solid $green-color;
						border-radius: 50%;
						padding: 2px;
						color: $green-color;
					}
				}
				.process {
					color: $black-color;
					&.form-app-doclink, &.term-service-doclink {
						.icon {
							color: $black-color;
						}
					}
					&.sre,&.sid {
						.icon {
							color: $black-color;
							border: 1px solid $black-color;
						}
					}
				}
			}
			.info {
				font-size: 12px;
				font-weight: bold;
				color: $green-color;
				margin-left: 37px;
				margin-top: 10px;
			}
		}
	}
}

@media (max-width: 991px) {
	.registration-status {
		.wrap-identity {
			flex-direction: column;
			margin: 0 10px 10px 10px;
			.column {
				margin-bottom: 10px;
			}
		}
		.wrap-summary {
			padding: 0 5px;
		}
	}
}