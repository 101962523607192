@import "../assets/sass/variable.scss";
.information-content {
	padding: 15px;
	display: table-cell;
	vertical-align: middle;
	.title {
		text-transform: uppercase;
		color: $yellow-color;
		font-weight: bold;
		font-size: 24px;
		margin: 20px 0;
	}
	.description {
		color: $black-color;
		margin-bottom: 30px;
		text-align: left;
	}
}