.intl-tel-input {
	width: 100%;
	.selected-flag {
		outline: none;
		background-color: unset !important;
		.iti-flag {
			position: absolute;
		    left: 5px;
		    top: 50%;
		    transform: translateY(-50%);
		}
		.selected-dial-code {
			padding-left: 30px;
		}
		.arrow {
		    position: absolute;
		    right: 5px;
		    top: 50%;
		    transform: translateY(-50%);
		}
	}
	.form-input {
		padding-left: 90px !important;
	}
}