@import "../assets/sass/variable.scss";
.registration-content {
  padding: 15px;
  .title {
    font-weight: bold;
    font-size: 22px;
  }
  .wrap-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
}
@media (max-width: 991px) {
  .registration-content {
    padding: 15px 0;
    .wrap-btn {
      position: relative;
    }
	.blockSection {
		padding: 15px;
		margin: 30px 0;
		box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	  }
  }
}
