@import "~bootstrap/scss/bootstrap";
@import "fonts";
@import "variable";

body {
	font-family: 'Muli';
	font-size: 18px;
}

.form-row > [class*="col-"], .form-row > .col {
	padding-right: 15px;
	padding-left: 15px;
}

.link-href {
	font-weight: bold;
	font-style: italic;
	color: $blue-color;
	text-decoration: none;
	padding-bottom: 1px;
	border-bottom: 1px solid transparent;
	transition: all 0.5s;
}
.container .row {
	.col-12 {
		padding: 0 10px;
	}
}
.fieldset {
	text-align: left;
	color: $blue-color;
	padding-top: 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    .info {
		text-transform: none;
		color: $black-color;
		letter-spacing: normal;
		display: inline-block;
		.marked {
			color: $yellow-color;
			display: inline-block;
		}
    }
}

@media (max-width: 991px) {
	.form-row > [class*="col-"], .form-row > .col {
		padding-right: 10px;
		padding-left: 10px;
	}
	.container .row {
		.col-12 {
			padding: 0 5px;
		}
	}
}