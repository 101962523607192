@import "../../assets/sass/variable.scss";
.react-datepicker-wrapper {
	display: block;
	.react-datepicker__input-container {
		display: block;
	}
}

.react-datepicker {
	font-family: 'Muli';
	font-size: 14px;
	border: 1px solid #efefef;
	.react-datepicker__day-name {
		font-weight: bold;
	}
	.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
		width: 2rem;
		line-height: 2rem;
		margin: 0.1rem;
	}
	.react-datepicker__day--selected:hover, .react-datepicker .react-datepicker__day--in-selecting-range:hover, .react-datepicker .react-datepicker__day--in-range:hover, .react-datepicker .react-datepicker__month-text--selected:hover, .react-datepicker .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker .react-datepicker__month-text--in-range:hover, .react-datepicker .react-datepicker__quarter-text--selected:hover, .react-datepicker .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker .react-datepicker__quarter-text--in-range:hover {
		background-color: $blue-color;
	}
	.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover {
		background-color: $blue-color;
	}
	.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover {
		background-color: $blue-color;
	}
	.btn-datepicker {
	    border: 0;
	    margin: 0 10px;
	    padding: 0 10px;
	}
	.head-datepicker {
	    display: flex;
	    margin: 10px;
	    justify-content: center;
	    .select-datepicker {
	    	border: 1px solid $black-color;
	    	outline: none;
	    }
	}
}