@import "../assets/sass/variable.scss";
.stepform-content {
	margin-top: 15px;
	position: relative;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 30px 0 15px;
		li {
			vertical-align: top;
			display: inline-block;
			width: 150px;
			font-size: 13px;
			padding: 0 10px;
			color: $black-color;
			position: relative;
			&:first-child {
				// padding-left: 0;
				&:before {
					width: 50%;
					left: 50%;
				}
			}
			&:last-child {
				// padding-right: 0;
				&:before {
					width: 150%;
					right: 50%;
					left: -100%;
				}
			}
			&:after {
				content: "";
				position: absolute;
			    width: 5px;
			    height: 5px;
			    border-radius: 50%;
			    background-color: $black-color;
				top: -14px;
			    left: 50%;
			    transform: translateX(-50%);
			    z-index: 1;
			}
			&:before {
				position: absolute;
			    content: "";
			    border-bottom: 1px solid #c4c4c4;
			    top: -12px;
			    left: -50%;
			    width: 100%;
			    z-index: 0;
			}
			&.active {
				&:first-child {
					&:before {
						width: 0;
					}
				}
				&:before {
					border-bottom: 1px solid $yellow-color;
					z-index: 1;
				}
				// &:not(:last-child):before {
				// 	border-bottom: 1px solid $yellow-color;
				// }
				&:after {
					border: 5px solid $yellow-color;
					background-color: $white-color;
					width: 15px;
					height: 15px;
					top: -19px;
					z-index: 2;
				}
				.icon {
					background-color: black;
					position: absolute;
				    top: -16px;
				    left: 50%;
				    transform: translateX(-50%);
				    z-index: 1;
				    color: $white-color;
				    background-color: $yellow-color;
				    font-size: 10px;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.stepform-content {
		ul {
			li {
				display: none;
				padding: 0 7px;
				max-width: 100px;
				&.prev {
					display: inline-block;
					&:before {
						width: 50%;
						right: 0;
						left: unset;
					}
				}
				&.current {
					display: inline-block;
					& + li {
						display: inline-block;
						&:before {
							// width: 50%;
						}
					}
				}
			}
		}

	}
}