@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-Regular.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Muli-Regular.woff') format('woff'), 
       url('../fonts/Muli-Regular.ttf')  format('truetype'), 
       url('../fonts/Muli-Regular.svg#Muli-Regular') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-Light.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Muli-Light.woff') format('woff'), 
       url('../fonts/Muli-Light.ttf')  format('truetype'), 
       url('../fonts/Muli-Light.svg#Muli-Light') format('svg');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Muli-SemiBold.woff') format('woff'), 
       url('../fonts/Muli-SemiBold.ttf')  format('truetype'), 
       url('../fonts/Muli-SemiBold.svg#Muli-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-Bold.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Muli-Bold.woff') format('woff'), 
       url('../fonts/Muli-Bold.ttf')  format('truetype'), 
       url('../fonts/Muli-Bold.svg#Muli-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-Italic.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Muli-Italic.woff') format('woff'), 
       url('../fonts/Muli-Italic.ttf')  format('truetype'), 
       url('../fonts/Muli-Italic.svg#Muli-Italic') format('svg');
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli.eot?#iefix') format('embedded-opentype'),  
  	   url('../fonts/Muli.woff') format('woff'), 
  	   url('../fonts/Muli.ttf')  format('truetype'), 
  	   url('../fonts/Muli.svg#Muli') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-ExtraLight.eot?#iefix') format('embedded-opentype'),  
  	   url('../fonts/Muli-ExtraLight.woff') format('woff'), 
  	   url('../fonts/Muli-ExtraLight.ttf')  format('truetype'), 
  	   url('../fonts/Muli-ExtraLight.svg#Muli-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),  
       url('../fonts/Muli-BoldItalic.woff') format('woff'), 
       url('../fonts/Muli-BoldItalic.ttf')  format('truetype'), 
       url('../fonts/Muli-BoldItalic.svg#Muli-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}
