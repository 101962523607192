@import "../../assets/sass/variable.scss";
.alert-custom {
    text-align: left;
    border: 0;
    background: unset;
    font-weight: bold;
    padding: 15px;
    font-size: 16px;
    &.alert-danger {
    	color: #dc3545;
    }
    &.alert-success {
    	color: #30cc70;
    }
}