@import "../assets/sass/variable.scss";
.maintenance-page {
	width: 100%;
	min-height: 100vh;
	text-align: center;
	position: relative;
	// border: 30px solid $blue-color;
	.wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		.logo {
			width: 250px;
		    margin: auto;
		    margin-bottom: 25px;
		}
		.title {
			color: $yellow-color;
		}
		.desc {
		}
		p {
			color: $black-color;
		}
		.video {
			width: 500px;
			margin: 25px auto;
		}
		.info {
			a {
				color: $blue-color;
			}
		}
	}
}

@media (max-width: 480px) {
	.maintenance-page {
		.wrapper {
			.logo {
				margin: 0 auto;
			}
			.title {
				font-size: 16px;
			}
			.video {
				width: 100%;
			}
			.info {
				margin: 0;
			}
			font-size: 12px;
		}
	}
}