@import "../assets/sass/variable.scss";
.submitted-content {
	padding: 15px;
	display: table-cell;
	vertical-align: middle;
	.check-circle {
		width: 56px;
		height: 56px;
		display: inline-block;
		background-color: $green-color;
		color: #fff;
		border-radius: 50%;
		font-size: 36px;
		margin: 15px;
	}

	.information {
		padding: 15px 0;

		.title {
			font-weight: bold;
			font-size: 20px;
			text-transform: uppercase;
			color: $green-color;
		}
		.description {
			padding: 5px 170px;
			color: $black-color;
		}
	}

	.divider {
	    display: flex;
	    align-items: center;
	    text-align: center;
	    margin: 50px 0;
	    padding: 0 130px;
	    text-transform: uppercase;
	    color: $black-color;
	    font-weight: 200;
		&:before, &:after {
		    content: '';
		    flex: 1;
		    border-bottom: 1px solid #e4e4e4;
		    border-bottom: 1px solid #e4e4e4;
		}
		&:before {
			margin-right: 15px;
		}
		&:after {
			margin-left: 15px;
		}
		.img {
			max-width: 100%;
			height: auto;
			width: 20px;
		}
	}

	.check-progress {
		.info {
			color: $black-color;
			margin: 20px;
		}
	}
	.pdf-btn {
		margin: 10px;
		display: inline-block;
	}
}

/* Download App */
.download-app {
    padding: 25px 14px;
    margin: 30px 0 0 0;
	position: relative;

	.heading {
		p {
			color: $blue-color;
			font-weight: bold;
		}
	}
	.app-button-container {
		display:flex;
		justify-content: center;
		img {
			margin: 0 5px;
			width: 100%;
			max-width: 200px;
		}
	}
	.naik-for-pc {
		margin: 15px 0;
		p {
			margin: 0;
			padding: 0;
		}
		a {
			color: $blue-color;
			font-size: 16px;
		}
	}

	.download-app-hint {
		p {
			margin: 15px 0 0 0;
			font-size: 14px;
			color: $black-color;
		}
	}
}

@media (max-width: 991px) {
	.submitted-content {
		.information {
			.description {
				padding: 0;
			}
		}
	}
}