@import "fonts";
@import "variable";

.navigate-webcam {
    text-align: center;
}

.liveness-error-wrapper {
    h3 {
        font-size: 21px;
        font-weight: bold;
        color: $red-color;
    }

    p {
        font-size: 12px;
    }
}

.image-preview {
    position: relative;
    .liveness-processing {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 5px 10px;
    }
}